import { createContext, useState } from "react";

export const LoadingContext = createContext();

export default function LoadingContextProvider(props) {
  const [showLoading, setShowLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ showLoading, setShowLoading }}>
      {props.children}
    </LoadingContext.Provider>
  );
}
