import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { getUser } from "../service/api";
import constants from "../utils/constants";

export const AuthContext = createContext();

export default function AuthContextProvider(props) {
  const [user, setUser] = useState();
  const [isFetch, setFetch] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    let unsubscribe = null;
    unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        let userData = await getUser(currentUser.accessToken);
        if (userData) {
          userData = await userData.json();
          const { email, displayName, accessToken, uid, photoURL } =
            currentUser;
          const subscribeStatus = constants.PLAN_STATUS_ACTIVE.includes(
            userData?.subscribe?.status
          );
          setUser({
            email,
            displayName,
            accessToken,
            uid,
            photoURL,
            subscribeStatus,
            userData,
          });
        }
      } else {
        setUser(null);
      }
      setFetch(false);
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, isFetch, setFetch }}>
      {props.children}
    </AuthContext.Provider>
  );
}
