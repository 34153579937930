import { createContext, useState } from "react";

export const FilterChartContext = createContext();

export default function FilterChartContextProvider(props) {
  const [filtersChart, setFiltersChart] = useState([]);
  const [firstYearInvestment, setFirstYearInvestment] = useState(
    new Date().getFullYear()
  );

  return (
    <FilterChartContext.Provider
      value={{
        filtersChart,
        setFiltersChart,
        firstYearInvestment,
        setFirstYearInvestment,
      }}
    >
      {props.children}
    </FilterChartContext.Provider>
  );
}
