const PLANS = {
  PREMIUM: {
    planId: "price_1PFwxEBG3WQmhIAV0xZlHc99",
    price: 5.99,
    description: "Assinatura Premium",
  },
};

const PLAN_STATUS_ACTIVE = ["trialing", "active"];

const CONFIGS = {
  EXPIRE_DATA_IN_MINUTES: 240,
  KEY_LS_DATA: "lsData",
};

const CLASS = {
  ACAO: "Ação",
  FII: "FII",
  FIAGRO: "Fiagro",
  FI_INFRA: "FI-INFRA",
  BDR: "BDR",
  ETF: "ETF",
  STOCK: "STOCK",
  REIT: "REIT",
  ETF_EUA: "ETF-EXTERIOR",
  RENDA_FIXA: "Renda Fixa",
  CRIPTOMOEDA: "Criptomoeda",
  RENDA_FIXA_OUTROS: "Renda Fixa - Outros",
};
const CLASS_EXTERNAL_LIST = [CLASS.ETF_EUA, CLASS.STOCK, CLASS.REIT];

const COLORS = {
  BLUE: "#10439F",
  PRIMARY: "#874CCC",
  PRIMARY_2: "purple",
  SECONDARY: "#C65BCF",
  SECONDARY_2: "#F27BBD",
  RED: "#dd1669",
  RED_LIGTH: "#fcdeea",
  GREEN: "#388e3c",
  GREEN_LIGTH: "#dcf1e4",
  ACAO: "#980000",
  FII: "#0d0985",
  FIAGRO: "#059611",
  FI_INFRA: "#3D85C6",
  BDR: "#6e3f08",
  ETF: "#0b6e5c",
  STOCK: "#dc12b7",
  REIT: "#b90dab",
  ETF_EUA: "#d41b59",
  RENDA_FIXA: "#abae0a",
  CRIPTOMOEDA: "#5e0098",
  RENDA_FIXA_OUTROS: "#abae0a",
  TEXT_CLEAR: "#9d9d9d",
};

const CHART_SELECT_FILTERS = {
  ALL: "Todo período",
  LAST12: "Últimos 12 meses",
  Y2: "Últimos 2 anos",
  Y3: "Últimos 3 anos",
  Y5: "Últimos 5 anos",
  Y10: "Últimos 10 anos",
};

const KEYS_SELECT_FILTERS = {
  "Todo período": "*",
  "Últimos 12 meses": 12,
  "Últimos 2 anos": 24,
  "Últimos 3 anos": 36,
  "Últimos 5 anos": 60,
  "Últimos 10 anos": 120,
};

export default {
  PLANS,
  PLAN_STATUS_ACTIVE,
  CONFIGS,
  CLASS,
  CLASS_EXTERNAL_LIST,
  COLORS,
  CHART_SELECT_FILTERS,
  KEYS_SELECT_FILTERS,
};
