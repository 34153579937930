import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    background: {
      // default: "",
      // paper: "",
    },
    primary: {
      main: "#2E0249",
    },
    // secondary: {
    //   main: '#19857b',
    // },
    mode: "light",
    // mode: "dark",
    error: {
      main: red.A400,
    },
  },
});

export default theme;
