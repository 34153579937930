import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  convertCurrencyReal,
  mountHeaderDataTable,
} from "../../../utils/utils";
import constants from "../../../utils/constants";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box } from "@mui/material";

const customStyles = {
  rows: {
    style: {
      fontSize: "15px",
    },
  },
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headCells: {
    style: {
      //   flexBasis: "none",
      fontSize: "15px",
      fontWeight: "bold",
    },
  },
  headRow: {
    style: {
      // color: "#fff",
      // backgroundColor: "#874CCC",
    },
  },
};

// const conditionalRowStyles = [
//   {
// 		when: row => row.vVari < 0,
// 		style: {
// 			// backgroundColor: 'rgba(216, 47, 47, 0.1)',
// 			color: constants.COLORS.RED,
//       '&:hover': {
//         backgroundColor: 'rgba(63, 195, 128, 0.7)',
//       },
// 		},
// 	},
// ]

const conditionalCellStyles = [
  {
    when: (row) => row.vVari < 0,
    style: {
      color: constants.COLORS.RED,
    },
  },
  {
    when: (row) => row.vVari >= 0,
    style: {
      color: constants.COLORS.GREEN,
    },
  },
];

const TabResumeTable = (props) => {
  const { data } = props;
  const columns = [
    {
      name: "Grupo",
      selector: (row) => row.classe,
      sortable: true,
      width: "170px",
      left: true,
    },
    {
      name: mountHeaderDataTable(data?.walletGrouping?.headers[1]),
      selector: (row) => row.percVari,
      format: (row) => (
        <Box>
          {Number(row.percVari.replace(",", ".")) < 0 ? (
            <ArrowDownward fontSize="small" sx={{ fontSize: "12px" }} />
          ) : (
            <ArrowUpward fontSize="small" sx={{ fontSize: "12px" }} />
          )}
          {row.percVari}%
        </Box>
      ),
      sortable: true,
      conditionalCellStyles,
    },
    {
      name: mountHeaderDataTable(data?.walletGrouping?.headers[2]),
      selector: (row) => (
        <Box>
          {row.percVari < 0 ? (
            <ArrowDownward fontSize="small" sx={{ fontSize: "12px" }} />
          ) : (
            <ArrowUpward fontSize="small" sx={{ fontSize: "12px" }} />
          )}
          {convertCurrencyReal(row.percVari)}%
        </Box>
      ),
      sortable: true,
      conditionalCellStyles,
    },
    {
      name: mountHeaderDataTable(data?.walletGrouping?.headers[3]),
      selector: (row) => `${convertCurrencyReal(row.percWallet)}%`,
      sortable: true,
    },
    {
      name: mountHeaderDataTable(data?.walletGrouping?.headers[4]),
      selector: (row) => convertCurrencyReal(row.vWallet),
      sortable: true,
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataTable
        responsive
        striped
        highlightOnHover
        customStyles={customStyles}
        // dense
        // conditionalRowStyles={conditionalRowStyles}
        columns={columns}
        data={data?.walletGrouping?.rows || []}
      />
      {/* <DataGrid
        rows={rows}
        columns={columns}
        hideFooterPagination={true}
        disableColumnMenu
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
      /> */}
    </div>
  );
};

export default TabResumeTable;
