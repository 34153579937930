import React from "react";
import PropTypes from "prop-types";
import MyRotas from "./routes";
import imageConstruction from "./images/construção.png";
import { Card, CardContent } from "@mui/material";
function App(props) {
  if (process.env.REACT_APP_HAS_CONSTRUCTION === "true") {
    return (
      <Card>
        <CardContent>
          <img src={imageConstruction} width={"100%"} />
        </CardContent>
      </Card>
    );
  }
  return <MyRotas />;
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default App;
