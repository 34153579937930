import { jwtDecode } from "jwt-decode";
import constants from "../utils/constants";
import moment from "moment";
import { getAuth } from "firebase/auth";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Monta o cabeçalho da requisição
 * @param {*} accessToken token do usuário
 * @returns
 */
const composeHeader = async (accessToken) => {
  let act = accessToken;
  const jwt = jwtDecode(accessToken);
  const currentDateTime = moment().unix();
  const c = moment.unix(currentDateTime).format("YYYY/MM/DD HH:mm:ss");
  const d = moment.unix(jwt.exp).format("YYYY/MM/DD HH:mm:ss");

  console.log(`::ATUAL::${c}-----::EXPIRE::${d}`);

  const cd = moment.unix(currentDateTime);
  const exp = moment.unix(jwt.exp);

  const expired = moment.duration(exp.diff(cd)).asSeconds() <= 0;

  if (expired) {
    act = await getAuth().currentUser.getIdToken(true);
  }

  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${act}`,
  };
};

/**
 * Cria uma nova sessão para o portal do cliente
 * @param {*} accessToken token do usuário
 * @returns redirecionamento para o portal
 */
const sendCustomerPortal = async (accessToken) => {
  return await fetch(`${API_URL}/api/checkout/customer-portal`, {
    method: "post",
    mode: "cors",
    headers: await composeHeader(accessToken),
    body: JSON.stringify({}),
  });
};

/**
 * Cria um novo usuário se não existir
 * @param {*} accessToken
 * @param {*} body
 */
const saveUser = async (accessToken, body) => {
  return await fetch(`${API_URL}/api/user`, {
    method: "post",
    mode: "cors",
    headers: await composeHeader(accessToken),
    body: JSON.stringify(body),
  });
};

/**
 * Salva configurações do sistema
 * @param {*} accessToken
 * @param {*} body
 */
const saveUserSettings = async (accessToken, body) => {
  return await fetch(`${API_URL}/api/user/settings`, {
    method: "post",
    mode: "cors",
    headers: await composeHeader(accessToken),
    body: JSON.stringify(body),
  });
};

/**
 * Retorna um usuário se não existir e seus planos
 * @param {*} accessToken
 * @param {*} body
 */
const getUser = async (accessToken) => {
  return await fetch(`${API_URL}/api/user/data`, {
    method: "get",
    mode: "cors",
    headers: await composeHeader(accessToken),
  });
};

/**
 * Cria uma nova sessão de compra especifica do cliente
 * @param {*} accessToken
 * @returns
 */
const createCheckoutSession = async (accessToken, body) => {
  return await fetch(`${API_URL}/api/checkout/create-checkout-session`, {
    method: "post",
    mode: "cors",
    headers: await composeHeader(accessToken),
    body: JSON.stringify(body),
  });
};

/**
 * Syncronizar dados
 * @param {*} accessToken
 * @returns
 */
const sheetSync = async (accessToken) => {
  const currentDateTime = new Date().getTime();
  let fullSheetData;
  if (lsDataIsExpire()) {
    try {
      const result = await fetch(`${API_URL}/api/sheet/sync`, {
        method: "get",
        mode: "cors",
        headers: await composeHeader(accessToken),
      });
      if (result.status !== 200) {
        throw new Error("Falha ao sincronizar dados");
      }
      fullSheetData = await result.json();
      localStorage.setItem(
        constants.CONFIGS.KEY_LS_DATA,
        JSON.stringify({ ...fullSheetData, expires: currentDateTime })
      );
      return fullSheetData;
    } catch (error) {
      throw new Error(
        "falha ao atualizar dados, por favor, tente novamente mais tarde"
      );
    }
  } else {
    return JSON.parse(localStorage.getItem(constants.CONFIGS.KEY_LS_DATA));
  }
};

const lsDataIsExpire = () => {
  const currentDateTime = new Date().getTime();
  const lsData = JSON.parse(
    localStorage.getItem(constants.CONFIGS.KEY_LS_DATA)
  );
  if (lsData) {
    const diffMinutes = parseInt((currentDateTime - lsData.expires) / 60000);
    if (diffMinutes >= constants.CONFIGS.EXPIRE_DATA_IN_MINUTES) {
      return true;
    }
  } else {
    return true;
  }
  return false;
};

export {
  createCheckoutSession,
  sendCustomerPortal,
  saveUser,
  saveUserSettings,
  getUser,
  sheetSync,
};
