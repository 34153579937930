import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";

import { useFormik } from "formik";
import * as yup from "yup";
import { saveUserSettings } from "../../service/api";
import useAuth from "../../hooks/useAuth";
import { useLoaderData } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import { Settings } from "@mui/icons-material";

const validationSchema = yup.object({
  // name: yup
  //     .string('Digite seu nome'),
  // document_number: yup.string('Entre com seu CPF'),
  url: yup
    .string("Enter your password")
    .min(8, "A url deve conter no minimo 8 caracteres")
    .required("Link da planilha é obrigatório"),
});

const TabSettings = () => {
  const { user, setUser } = useAuth();
  const { setShowLoading } = useLoading();
  const formik = useFormik({
    initialValues: {
      url: user?.userData.settings?.url || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      try {
        setShowLoading(true);
        let userLogged = await saveUserSettings(user?.accessToken, {
          ...values,
        });
        userLogged = await userLogged.json();
        setUser({
          ...user,
          userData: { ...user?.userData, settings: userLogged.settings },
        });
        setShowLoading(false);
      } catch (error) {
        setShowLoading(false);
        throw new Error("Falha ao salvar configurações");
      }
    },
  });

  return (
    <Box display={"flex"} justifyContent={"center"} mt={3}>
      <Card>
        <CardHeader title="Configurações da Conta" />
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <FormLabel>Insira o link para a planilha</FormLabel>
            <TextField
              placeholder="Digite o link da planilha"
              helperText={formik.touched.url && formik.errors.url}
              error={formik.touched.url && Boolean(formik.errors.url)}
              id="url"
              name="url"
              fullWidth={true}
              value={formik.values.url}
              onChange={formik.handleChange}
            />
            <Button
              title="Salvar"
              sx={{ mt: 2 }}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Salvar
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TabSettings;
