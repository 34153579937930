import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:
    process.env.REACT_PRODUCTION === "true"
      ? "mydashboard.com.br"
      : "mydashboard-7cfca.firebaseapp.com",
  projectId: "mydashboard-7cfca",
  storageBucket: "mydashboard-7cfca.appspot.com",
  messagingSenderId: "861661991297",
  appId: "1:861661991297:web:e12242a6c94e713273d880",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
